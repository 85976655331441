/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
const TimerTelur = {
  async render() {
    return `
    <div style="display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    margin: 0; 
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;">
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; padding: 0; margin-top: 50px;">
          <div style="background-color: white; border-radius: 20px; width: 100%; max-width: 500px; overflow: hidden;">
            <div style="background-color: #ffcc00; color: #fff; padding: 20px; text-align: center; font-size: 28px; font-weight: bold; text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);">
              <span>🥚 Egg Timer</span>
            </div>
            <div style="padding: 30px;">
              <div style="display: flex; justify-content: space-between; margin-bottom: 30px; flex-wrap: wrap; gap: 10px;">
                <div style="background-color: #f9f9f9; border-radius: 15px; padding: 15px; text-align: center; cursor: pointer; width: calc(33.33% - 10px); transition: all 0.3s ease; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);" data-time="3" id="soft">
                  <img src="../images/soft-boiled.png" alt="Soft-boiled egg" style="width: 60px; height: 60px; margin-bottom: 10px;" />
                  <h3 style="margin: 0;">Soft-boiled</h3>
                  <p style="margin: 0;">3:00 minutes</p>
                </div>
                <div style="background-color: #f9f9f9; border-radius: 15px; padding: 15px; text-align: center; cursor: pointer; width: calc(33.33% - 10px); transition: all 0.3s ease; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);" data-time="8" id="hard">
                  <img src="../images/hard-boiled.png" alt="Hard-boiled egg" style="width: 60px; height: 60px; margin-bottom: 10px;" />
                  <h3 style="margin: 0;">Hard-boiled</h3>
                  <p style="margin: 0;">8:00 minutes</p>
                </div>
                <div style="background-color: #f9f9f9; border-radius: 15px; padding: 15px; text-align: center; cursor: pointer; width: calc(33.33% - 10px); transition: all 0.3s ease; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);" data-time="2" id="fried">
                  <img src="../images/friedegg.png" alt="Fried egg" style="width: 60px; height: 60px; margin-bottom: 10px;" />
                  <h3 style="margin: 0;">Fried</h3>
                  <p style="margin: 0;">2:00 minutes</p>
                </div>
              </div>
  
              <!-- Animation container -->
              <div id="animationContainer" style="text-align: center; margin: 20px 0; height: 120px; display: flex; justify-content: center; align-items: center; visibility: hidden;">
                <img
                  src="../images/loading1.png"
                  alt="Loading 1"
                  id="loading1"
                  style="width: 100px; height: 100px; display: none;"
                />
                <img
                  src="../images/loading2.png"
                  alt="Loading 2"
                  id="loading2"
                  style="width: 100px; height: 100px; display: none;"
                />
                <img
                  src="../images/loading3.png"
                  alt="Loading 3"
                  id="loading3"
                  style="width: 100px; height: 100px; display: none;"
                />
                <img
                  src="../images/donee.png"
                  alt="Done"
                  id="doneImage"
                  style="width: 200px; height: 200px; display: none;"
                />
              </div>
  
              <div style="text-align: center; margin: 30px 0;">
                <div id="time" style="font-size: 72px; font-weight: bold; color: #333;">03:00</div>
              </div>
  
              <div style="display: flex; justify-content: center; gap: 15px;">
                <button id="start" style="background-color: #ffcc00; color: white; border: none; padding: 12px 25px; border-radius: 30px; cursor: pointer; font-size: 16px; font-weight: bold; transition: background-color 0.3s; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">Start</button>
                <button id="reset" disabled style="background-color: #ccc; color: white; border: none; padding: 12px 25px; border-radius: 30px; cursor: not-allowed; font-size: 16px; font-weight: bold; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">Reset</button>
              </div>
  
              <div id="status" style="text-align: center; margin-top: 20px; font-size: 18px; color: #666; height: 25px;"></div>
            </div>
          </div>
        </div>
        </div>
      `;
  },

  async afterRender() {
    // Elements
    const eggTypes = document.querySelectorAll('[data-time]');
    const timeDisplay = document.getElementById('time');
    const startButton = document.getElementById('start');
    const resetButton = document.getElementById('reset');
    const statusText = document.getElementById('status');
    const cookingSound = new Audio('../images/cooking.mp3');
    const doneSound = new Audio('../images/done2.mp3');

    // Animation elements
    const animationContainer = document.getElementById('animationContainer');
    const loading1 = document.getElementById('loading1');
    const loading2 = document.getElementById('loading2');
    const loading3 = document.getElementById('loading3');
    const doneImage = document.getElementById('doneImage');
    const loadingImages = [loading1, loading2, loading3];

    // Variables
    let timer;
    let animationTimer;
    let timeLeft = 180; // Default 3 minutes in seconds
    let isRunning = false;
    let currentImageIndex = 0;

    // Set initial time display
    updateTimeDisplay();
    hideAllLoadingImages();

    // Egg type selection
    eggTypes.forEach((type) => {
      type.addEventListener('click', function () {
        if (isRunning) return;

        eggTypes.forEach((t) => (t.style.backgroundColor = '#f9f9f9'));
        this.style.backgroundColor = '#333';
        this.style.color = 'white';
        timeLeft = parseInt(this.getAttribute('data-time')) * 60;
        updateTimeDisplay();
        statusText.textContent = '';
      });
    });

    // Start button
    startButton.addEventListener('click', () => {
      if (isRunning) {
        clearInterval(timer);
        clearInterval(animationTimer);
        cookingSound.pause();
        cookingSound.currentTime = 0;
        startButton.textContent = 'Resume';
        statusText.textContent = 'Paused';
        hideAnimationContainer();
      } else {
        cookingSound.play();
        cookingSound.loop = true;
        timer = setInterval(updateTimer, 1000);
        showAnimationContainer();
        startAnimation();
        startButton.textContent = 'Pause';
        statusText.textContent = 'Timer running...';
      }

      isRunning = !isRunning;
      resetButton.disabled = false;
    });

    // Reset button
    resetButton.addEventListener('click', () => {
      clearInterval(timer);
      clearInterval(animationTimer);
      cookingSound.pause();
      cookingSound.currentTime = 0;
      isRunning = false;

      const activeType = document.querySelector('[data-time]');
      timeLeft = parseInt(activeType.getAttribute('data-time')) * 60;

      updateTimeDisplay();
      startButton.textContent = 'Start';
      resetButton.disabled = true;
      statusText.textContent = '';
      hideAnimationContainer();
    });

    function updateTimer() {
      if (timeLeft <= 0) {
        clearInterval(timer);
        clearInterval(animationTimer);
        cookingSound.pause();
        cookingSound.currentTime = 0;
        isRunning = false;
        startButton.textContent = 'Start';
        statusText.textContent = "Time's up! Your egg is ready!";
        doneSound.play();

        // Show done image and hide loading images
        hideAllLoadingImages();
        doneImage.style.display = 'block';
        return;
      }
      timeLeft--;
      updateTimeDisplay();
    }

    function updateTimeDisplay() {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      timeDisplay.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    // Function to start the animation
    function startAnimation() {
      // Show the first image initially
      hideAllLoadingImages();
      doneImage.style.display = 'none'; // Ensure done image is hidden
      loadingImages[currentImageIndex].style.display = 'block';

      // Change image every second
      animationTimer = setInterval(() => {
        hideAllLoadingImages();
        currentImageIndex = (currentImageIndex + 1) % loadingImages.length;
        loadingImages[currentImageIndex].style.display = 'block';
      }, 1000);
    }

    // Hide all loading images
    function hideAllLoadingImages() {
      loadingImages.forEach((img) => {
        img.style.display = 'none';
      });
    }

    // Show animation container
    function showAnimationContainer() {
      animationContainer.style.visibility = 'visible';
    }

    // Hide animation container
    function hideAnimationContainer() {
      animationContainer.style.visibility = 'hidden';
      hideAllLoadingImages();
      doneImage.style.display = 'none'; // Ensure done image is hidden
    }
  },
};

export default TimerTelur;
